<template>
    <div class="overlay w-all p-2 lg:p-4">
        <BootcampTile>
            <slot />
        </BootcampTile>
    </div>
</template>
<style scoped>
.overlay {
    background-color: #E5E5E5;
}
</style>
